<template>
  <b-card class="blog-edit-wrapper">
    <!-- form -->
    <div class="text-center">
      <h4 class="mt-5">
        سيو الدورات الاونلاين
        <feather-icon icon="Edit2Icon" />
      </h4>
    </div>
    <validation-observer ref="addCourseFormvalidate">
      <b-form
        ref="form"
        class="mt-2"
        style="width: 100%"
        @submit.prevent="save"
      >
        <b-row>
          <b-col md="6">
            <b-form-group
              label="الفئة "
              label-for="blog-edit-course"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="Courses"
                rules=""
              >
                <v-select
                  id="blog-edit-category"
                  v-model="addCourseForm.category"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="type"
                  :options="categoriesList"
                  :reduce="(val) => val.id"
                  @input="getCoursesByCategory(addCourseForm.category)"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="الدورة "
              label-for="blog-edit-course"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="Courses"
                rules=""
              >
                <v-select
                  id="blog-edit-category"
                  v-model="addCourseForm.course"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="name"
                  :options="coursesList"
                  :reduce="(val) => val"
                  @input="getDuration(addCourseForm.course)"
                >
                  >
                  <template
                    v-if="!getCourse"
                    #no-options="{ search, searching, loading }"
                  >
                    اختر الفئة اولا ...
                  </template>
                  <template
                    v-else
                    #no-options="{ search, searching, loading }"
                  >
                    جار التحميل ...
                  </template>
                </v-select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col cols="4">
            <b-form-group
              label="العنوان"
              label-for="blog-content"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="Title"
              >
                <b-form-input v-model="addCourseForm.title" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="4">
            <b-form-group
              label="الوصف"
              label-for="blog-content"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="Description"
              >
                <b-form-input v-model="addCourseForm.description" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="4">
            <b-form-group
              label="الكلمات المفتاحية"
              label-for="blog-content"
              class="mb-2"
            >
              <b-form-tags v-model="addCourseForm.keyword" />
            </b-form-group>
          </b-col>

          <b-col
            cols="12"
            class="mt-50"
          >
          <b-button
            v-if="isAdd"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-1"
              @click="save"
            >
              اضافة
            </b-button>
            <b-button
            v-else
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-1"
              @click="save"
            >
              تعديل
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
    <b-row>
      <seo-online-list ref="table" />
    </b-row>
    <!--/ form -->
  </b-card>
</template>

<script>
import {
  BCard,
  BMedia,
  BForm,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BButton,
  BTable,
  BFormTags,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import vSelect from 'vue-select'
import { required, email } from '@validations'
import Ripple from 'vue-ripple-directive'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import { ref, reactive } from '@vue/composition-api'
import Vue from 'vue'
import store from '@/store'
import SeoOnlineList from './SeoOnlineList.vue'

export default {
  setup() {
    // const store = useStore();
    const refInputEl = ref(null)
    const refInputE2 = ref(null)
    const refPreviewEl = ref(null)
    const refPreviewE2 = ref(null)
    const Tabimage = ref('')
    const coursesList = ref([])
    const categoriesList = ref([])
    const table = ref()

    const isAdd = ref(true)
    const getCourse = ref(false)
    store.dispatch('categories/AllCategory').then(response => {
      console.log('response', response.data.data)
      categoriesList.value = response.data.data
    })
    const form = ref()
    const addCourseForm = reactive({
      course: '',
      category: '',
      title: '',
      description: '',
      keyword: '',
    })
    const getDuration = item => {
      const { id } = item
      console.log(id)

      store.dispatch('seo/GetSeoCourseOnline', { id }).then(response => {
        console.log(response)
        if(response.data.data.id)
        {
          isAdd.value=false
        // addCourseForm.related_courses=JSON.parse(response?.data.related_courses)
        addCourseForm.keyword = response?.data.data.keywords.split(',')
        addCourseForm.title = response?.data.data.title
        addCourseForm.description = response?.data.data.description
      }
        else{
          isAdd.value=true
        }
      })
    }
    const getCoursesByCategory = id => {
      addCourseForm.course = ''
      addCourseForm.title = ''
              addCourseForm.description = ''
              addCourseForm.keyword = ''
      getCourse.value = true
      store
        .dispatch('categories/getCoursesByCategory', {
          online: 1,
          ' category_id': id,
        })
        .then(response => {
          console.log('response', response.data)
          coursesList.value = response.data.data
        })
    }
   
    const { inputImageRenderer } = useInputImageRenderer(
      refInputEl,
      base64 => {
        refPreviewEl.value.src = base64
      },
    )
    const { inputImageRendererTab } = useInputImageRenderer(
      refInputE2,
      base64 => {
        refPreviewE2.value.src = base64
      },
    )
    const addCourseFormvalidate = ref()

    const save = () => {
      console.log(addCourseForm)
      const formData = new FormData()

      addCourseFormvalidate.value.validate().then(success => {
        if (success) {
          formData.append('course_id', addCourseForm.course.id)
          formData.append('online', 1)
          formData.append('title', addCourseForm.title)
          formData.append('name', addCourseForm.name)
          formData.append('description', addCourseForm.description)
          formData.append('keywords', addCourseForm.keyword)

          store
            .dispatch('seo/addSeoCourseClassic', formData)
            .then(response => {
              addCourseForm.category = ''
              addCourseForm.course = ''
              addCourseForm.title = ''
              addCourseForm.description = ''
              addCourseForm.keyword = ''
              table.value.refetchData()
              form.value.reset()
              if(isAdd.value==true)
              {
              Vue.swal({
                title: 'تم الاضافة بنجاح ',
                icon: 'success',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })
            }
            else{
              Vue.swal({
                title: 'تم التعديل بنجاح ',
                icon: 'success',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })
              isAdd.value==true
            }
            })
            .catch(error => {
              Vue.swal({
                title: '',
                text: `${error.response.data.message}`,
                icon: 'error',
                confirmButtonText: 'حسنا',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })
            })

          console.log('alt_header_image', addCourseForm)
        }
      })
    }
    const tableColumns = [
      { key: 'id', label: 'ID' },
      { key: 'name', label: ' Course Name' },
      { label: 'Title', key: 'link_id' },
      { key: 'name', label: 'Descripation' },
      { label: 'KeyWords', key: 'Date' },

      { key: 'actions' },
    ]
    return {
      isAdd,
      refInputEl,
      refPreviewEl,
      tableColumns,
      refInputE2,
      refPreviewE2,
      inputImageRendererTab,
      Tabimage,
      addCourseForm,
      inputImageRenderer,
      addCourseFormvalidate,
      coursesList,
      categoriesList,
      getDuration,
      table,
      form,
      getCourse,
      getCoursesByCategory,
      save,
      required,
      email,
    }
  },

  components: {
    BCard,
    BMedia,
    BTable,
    BFormTags,
    SeoOnlineList,
    ValidationProvider,
    ValidationObserver,
    BForm,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,

    vSelect,

  },

  directives: {
    Ripple,
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/quill.scss";
@import "@core/scss/vue/pages/page-blog.scss";
</style>
