<template>
  <b-card class="blog-edit-wrapper">
    <!-- form -->
    <div class="text-center">
      <h4 class="mt-5">
        سيو الدورة في المدن
        <feather-icon icon="Edit2Icon" />
      </h4>

    </div>
    <validation-observer ref="addCourseFormvalidate">
      <b-form
        ref="form"
        class="mt-2"
        style="width: 100%"
        @submit.prevent="save"
      >
        <b-row>

          <b-col md="4">
            <b-form-group
              label="الفئة "
              label-for="blog-edit-course"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="Courses"
                rules=""
              >
                <v-select
                  id="blog-edit-category"
                  v-model="addCourseForm.category"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="type"
                  :options="categoriesList"
                  :reduce="(val) => val.id"
                  @input="getCoursesByCategory(addCourseForm.category)"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="4">
            <b-form-group
              label="الدورة "
              label-for="blog-edit-course"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="Courses"
                rules=""
              >
                <v-select
                  id="blog-edit-category"
                  v-model="addCourseForm.course"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="name"
                  :options="coursesList"
                  :reduce="(val) => val.id"
                >
                  <template
                    v-if="!getCourse"
                    #no-options="{ search, searching, loading }"
                  >
                    اختر الفئة اولا ...
                  </template>
                  <template
                    v-else
                    #no-options="{ search, searching, loading }"
                  >
                    جار التحميل ...
                  </template>
                </v-select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group
              label="المدينة "
              label-for="blog-edit-course"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="Courses"
                rules=""
              >
                <v-select
                  id="blog-edit-category"
                  v-model="addCourseForm.city"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="name"
                  :options="citiesList"
                  :reduce="(val) => val.id"
                  @input="getContent(addCourseForm.course,addCourseForm.city)"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col cols="4">
            <b-form-group
              label="العنوان"
              label-for="blog-content"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="Description"
                rules="required"
              >
                <b-form-input

                  v-model="addCourseForm.title"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="4">
            <b-form-group
              label="الوصف"
              label-for="blog-content"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="Description"
                rules="required"
              >
                <b-form-input

                  v-model="addCourseForm.description"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="4">
            <b-form-group
              label="الكلمات المفتاحية"
              label-for="blog-content"
              class="mb-2"
            >

              <b-form-tags
                v-model="addCourseForm.keywords"

                class="form-control selectized"
              />

            </b-form-group>
          </b-col>

          <b-col
            cols="12"
            class="mt-50"
          >
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-1"
              @click="save"
            >
              حفظ
            </b-button>

          </b-col>
        </b-row>
      </b-form>

    </validation-observer>
    <b-row>
      <seo-in-city-list ref="table" />
    </b-row>
    <!--/ form -->
  </b-card>
</template>

<script>
import {
  BCard,
  BMedia,
  BForm,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BButton,
  BTable,
  BFormTags,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import vSelect from 'vue-select'
import { quillEditor } from 'vue-quill-editor'
import { required, email } from '@validations'
import Ripple from 'vue-ripple-directive'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import { ref, reactive } from '@vue/composition-api'
import Vue from 'vue'
import SeoInCityList from './SeoInCityList.vue'
import store from '@/store'

export default {
  setup() {
    // const store = useStore();
    const table = ref()
    const Tabimage = ref('')
    const categoriesList = ref([])
    const coursesList = ref([])
    const citiesList = ref([])
    const form = ref()
    const getCourse = ref(false)
    const getContent = (courseid, city) => {
      store.dispatch('seo/GetCourseInCity', { courseid, city }).then(response => {
        console.log(response)
        // addCourseForm.related_courses=JSON.parse(response?.data.related_courses)
        addCourseForm.keywords = response?.data.data.keywords.split(',')
        addCourseForm.title = response?.data.data.title
        addCourseForm.description = response?.data.data.description
      })
    }
    const getCoursesByCategory = id => {
      addCourseForm.course = ''
      getCourse.value = true
      store.dispatch('categories/getCoursesByCategory', {

        online: 0,
        ' category_id': id,

      })
        .then(response => {
          console.log('response', response.data)
          coursesList.value = response.data.data
        })
    }
    store.dispatch('cities/getAllcitites')
      .then(response => {
        citiesList.value = response.data.data
      })

    store.dispatch('categories/CategoryList')
      .then(response => {
        console.log('response', response.data.data)
        categoriesList.value = response.data
      })
    const addCourseForm = reactive({
      city: '',
      category: '',
      course: '',
      description: '',
      title: '',
      keywords: '',

    })

    const addCourseFormvalidate = ref()

    const save = () => {
      console.log(addCourseForm)
      const formData = new FormData()

      addCourseFormvalidate.value.validate().then(success => {
        if (success) {
          formData.append('course_id', addCourseForm.course)
          formData.append('city_id', addCourseForm.city)
          formData.append('category_id', addCourseForm.category)
          formData.append('description', addCourseForm.description)
          formData.append('title', addCourseForm.title)
          formData.append('keywords', addCourseForm.keywords)
          formData.append('online', 0)

          store.dispatch('seo/addCourseInCity', formData)
            .then(response => {
              form.value.reset()
              table.value.refetchData()
              Vue.swal({
                title: 'تم الاضافة بنجاح ',
                icon: 'success',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })
            }).catch(error => {
              Vue.swal({
                title: '',
                text: `${error.response.data.message}`,
                icon: 'error',
                confirmButtonText: 'حسنا',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })
            })

          console.log('alt_header_image', addCourseForm)
        }
      })
    }
    const tableColumns = [

      { key: 'id', label: 'ID' },
      { key: 'name', label: ' Course Name' },
      { label: 'Title', key: 'link_id' },
      { key: 'name', label: 'Descripation' },
      { label: 'KeyWords', key: 'Date' },

      { key: 'actions' },

    ]
    return {

      Tabimage,
      addCourseForm,
      getContent,
      addCourseFormvalidate,
      categoriesList,
      coursesList,
      citiesList,
      table,
      form,
      getCoursesByCategory,
      getCourse,
      save,
      required,
      email,
    }
  },

  components: {
    BCard,
    BMedia,
    BTable,
    BFormTags,
    ValidationProvider,
    BForm,
    SeoInCityList,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    vSelect,

  },

  directives: {
    Ripple,
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/quill.scss";
@import "@core/scss/vue/pages/page-blog.scss";
</style>
