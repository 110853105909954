<template>

  <div class="list-tabledata">

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>عرض</label>
            <v-select

              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entries</label>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="بحث..."
              />

            </div>
          </b-col>

        </b-row>

      </div>

      <b-table
        ref="refCourseListTable"
        class="position-relative"

        responsive
        :fields="tableColumns"
        primary-key="id"

        :items="allItem"
        striped

        show-empty
        empty-text="  يتم جلب البيانات"
      >

        <!-- Column: Status -->

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <a
            v-if="$can('delete', 'seo_course_city')"
            role="button"
            @click="DeleteSeoCotnent(data.item.id)"
          >

            <feather-icon icon="TrashIcon" /></a>

        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">
              عرض {{ dataMeta.from }} الى {{ dataMeta.to }} من {{ dataMeta.of }} المدخلات
            </span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalCategories"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BTable, BPagination,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import {
  ref, computed, watch, reactive,
} from '@vue/composition-api'
import Vue from 'vue'
import store from '@/store'
// import useCousresList from './useCousresList'
// import userStoreModule from '../userStoreModule'

export default {
  components: {

    // useCousresList,

    BCard,
    BRow,
    BCol,
    BFormInput,
    BTable,
    BPagination,
    vSelect,
  },
  setup() {
    const coursesList = ref([])
    const meta = reactive({})
    const perPageOptions = [25, 50, 100, 200]
    const perPage = ref(10)
    const refCourseListTable = ref(null)
    const totalCategories = ref(0)
    const searchQuery = ref('')
    const currentPage = ref(1)
    const allItem = ref([])
    const GetAllCourses = (ctx, callback) => {
      store.dispatch('seo/SeonInCity', {
        'filter[search]': searchQuery.value,

        per_page: perPage.value,
        page: currentPage.value,

      })
        .then(response => {
          console.log('courseincity', response.data)
          // coursesList.value=response.data.data
          allItem.value = response.data.data
          totalCategories.value = response.data.meta.total
          console.log('totalCategories', totalCategories)

          callback(data)
        })
    }
    const refetchData = () => {
      console.log('watch')
      refCourseListTable.value.refresh()
    }
    watch([currentPage, perPage, searchQuery], () => {
      console.log('watch')
      GetAllCourses()
    })

    GetAllCourses()

    const dataMeta = computed(() => {
      const localItemsCount = refCourseListTable.value ? refCourseListTable.value.localItems.length : 0
      return {
        from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
        to: perPage.value * (currentPage.value - 1) + localItemsCount,
        of: totalCategories.value,
      }
    })
    // GetAllCourses()
    const DeleteSeoCotnent = id => {
      Vue.swal({
        icon: 'warning',
        title: 'هل انت متاكد من الحذف ?',
        confirmButtonText: 'نعم',
        cancelButtonText: 'الغاء',
        customClass: {
          confirmButton: 'btn btn-primary',

        },
      }).then(result => {
        if (result.value) {
          store.dispatch('seo/DeleteClassicCourse', id)
            .then(response => {
              GetAllCourses()
              Vue.swal({
                title: 'تم الحذف  ',
                icon: 'success',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })
            })
        }
      })
    }
    const tableColumns = [

      { key: 'id', lanel: 'ID' },
      { key: 'course.name', label: 'الدورة ' },
      { label: 'العنوان', key: 'title' },
      {
        key: 'description',
        label: 'الوصف',

      },
      { key: 'keywords', label: 'الكلمات المفتاحية' },
      { key: 'actions', label: 'الخيارات' },

    ]

    return {
      tableColumns,
      coursesList,
      DeleteSeoCotnent,
      meta,
      perPageOptions,
      totalCategories,
      currentPage,
      perPage,
      dataMeta,
      refCourseListTable,
      refetchData,
      GetAllCourses,
      searchQuery,
      allItem,

    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
